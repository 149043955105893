import React from 'react';

import './styles.css';


const Spinner = () => {
  return (
    <div className="spinner"></div>
  );
};

export default Spinner