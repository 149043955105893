import React from "react";
import { useLocation } from "react-router-dom";

import { useDocumentTitle } from "utils/hooks";

import ContactForm from "components/ContactForm";

const Contacts = () => {
    useDocumentTitle("Contatti");
    const location = useLocation();
    const origin = location.state;

    return (
        <div className="wrapper">
            <ContactForm origin={origin} />
        </div>
    );
};

export default Contacts;
