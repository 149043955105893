import React from "react";
import { useParams } from "react-router-dom";

import { useGetArticleQuery } from "services/apiSlice";
import { formatItalianDate } from "utils/time";
import { useDocumentTitle } from "utils/hooks";

import ContactUsButton from "components/ContactUsButton";
import Spinner from "components/Spinner";

const Article = () => {
  const { filename } = useParams();
  const { data, error, isLoading } = useGetArticleQuery(filename);

  /* useDocumentTitle is always called no matter if data is available or
  not. this is to comply with the React rule that requires the hooks to
  always be called in the same order */
  useDocumentTitle(data ? data.title : "Caricamento...");

  return (
    <div className="wrapper">
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <h2>Non trovo l'articolo</h2>
      ) : data ? (
        <>
          <div className="container">
            <div className="msg_title__teaser">{data.title}</div>

            <div className="msg_header">
              <span className="small-text">{data.author}</span>
              {", "}
              <span className="small-text">{formatItalianDate(data.time)}</span>
            </div>

            <div
              className="msg_teaser"
              dangerouslySetInnerHTML={{ __html: data.article }}
            ></div>
            <div className="teaser__footer-left">
              <a className="link" href={"/news"}>
                indietro
              </a>
            </div>
          </div>
          <ContactUsButton origin={`Article: ${filename}`} />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Article;
