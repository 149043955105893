import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

const ContactUsButton = ({ origin }) => {
  return (
    <Link to="/contacts" state={origin}>
      <button className="contact-us-button">Contattaci</button>
    </Link>
  );
};

export default ContactUsButton;
