import React from "react";
import { useDocumentTitle } from "utils/hooks";

import MessageList from "components/MessageList";

const News = () => {
    useDocumentTitle("Notizie");
    return (
        <div className="wrapper">
            <MessageList what="news" />
        </div>
    );
};

export default News;
