import React from "react";
import { Link, useLocation } from "react-router-dom";

import MainTitle from "components/MainTitle";

import "./styles.css";

const Navbar = () => {
  const location = useLocation();

  return (
    <div>
      <Link to="/">
        <MainTitle title="Iteritur" />
      </Link>
      <nav className="navbar">
        <ul>
          <li className={location.pathname === "/lookingfor" ? "active" : ""}>
            <Link to="/lookingfor">Cerchiamo</Link>
          </li>
          <li className={location.pathname === "/mission" ? "active" : ""}>
            <Link to="/mission">Missione</Link>
          </li>
          {/*          <li className={location.pathname === "/news" ? "active" : ""}>
            <Link to="/news">Notizie</Link>
          </li>
          */}
          <li className={location.pathname === "/contacts" ? "active" : ""}>
            <Link to="/contacts" state="Navbar">
              Contattaci
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
