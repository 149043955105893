import React from "react";

import { formatItalianDate } from "utils/time";

import "./styles.css";

const Message = ({
  title,
  body,
  article,
  author,
  time,
  article_preview,
  filename,
}) => {
  let filenameNoExt;
  filename
    ? (filenameNoExt = filename.split(".").slice(0, -1).join("."))
    : (filenameNoExt = null);

  return (
    <div className="container">
      {time ? (
        <>
          <div className="msg_title__teaser">{title}</div>

          <div className="msg_header">
            <span className="small-text">{author}</span>
            {", "}
            <span className="small-text">{formatItalianDate(time)}</span>
          </div>

          <div
            className="msg_teaser"
            dangerouslySetInnerHTML={{ __html: article_preview }}
          ></div>

          <div className="teaser__footer-right">
            <a className="link" href={`/article/${filenameNoExt}`}>
              Leggi
            </a>
          </div>
        </>
      ) : (
        <>
          <h2 className="msg_title">{title}</h2>
          <div
            className="msg_body"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </>
      )}
    </div>
  );
};

export default Message;
