import React from "react";
import {
    useGetLookingForQuery,
    useGetMissionQuery,
    useGetNewsQuery,
} from "services/apiSlice";

import Message from "components/Message";
import Spinner from "components/Spinner";

const hookMapping = {
    lookingfor: useGetLookingForQuery,
    news: useGetNewsQuery,
    mission: useGetMissionQuery,
};

const MessageList = ({ what }) => {
    const useQueryHook = hookMapping[what];
    const { data, error, isLoading } = useQueryHook();

    return (
        <>
            {isLoading && <Spinner />}
            {error && <h2>Errore recupero dati.</h2>}
            {data &&
                data.map((msg, index) => (
                    <Message
                        key={index}
                        title={msg.title}
                        body={msg.body}
                        article={msg.article}
                        author={msg.author}
                        time={msg.time}
                        article_preview={msg.article_preview}
                        filename={msg.filename}
                    />
                ))}
        </>
    );
};

export default MessageList;
