const formatItalianDate = (dateString) => {
    /* convert a unix date string into the Italian format, e.g. 
    2024-01-06 to 6 gennaio 2024
     */

    const date = new Date(dateString);
    const options = {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    return new Intl.DateTimeFormat("it-IT", options).format(date);
};

export { formatItalianDate };
