import React, { useEffect } from "react";

const useDocumentTitle = (pageTitle, baseTitle = "Iteritur") => {
    /* set the page <title> dynamically */

    useEffect(() => {
        document.title = pageTitle ? `${pageTitle} | ${baseTitle}` : baseTitle;

        return () => {
            document.title = baseTitle;
        };
    }, [pageTitle, baseTitle]);
};

export { useDocumentTitle };
