import React from "react";

import "./styles.css";

const InputField = ({ type, name, placeholder, value, onChange, required }) => {
  let errorMessage = "";

  // Email validation message in Italian
  if (
    type === "email" &&
    value &&
    !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
  ) {
    errorMessage = "Inserisci un indirizzo email valido"; // Italian email validation message
  }

  return (
    <div style={{ position: "relative" }}>
      <input
        type={type}
        name={name}
        placeholder={required ? `${placeholder}*` : placeholder}
        value={value}
        onChange={onChange}
      />
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
};

export default InputField;
