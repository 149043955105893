import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getLookingFor: builder.query({
      query: () => "/get/lookingfor",
    }),
    getMission: builder.query({
      query: () => "/get/mission",
    }),
    getNews: builder.query({
      query: () => "/get/news",
    }),
    getArticle: builder.query({
      query: (title) => `/get/article/${title}`,
    }),
    submitForm: builder.mutation({
      query: (formData) => {
        /* IMPORTANT NOTE 
        don't set:
        const headers = new Headers();
        headers.set("Content-Type", "multipart/form-data");

        otherwise Flask won't recognize the data as such
        (this isn't documented to the best of my knowledge, chatGPT 
        told me that)
        */

        return {
          url: "/submit",
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useGetLookingForQuery,
  useGetMissionQuery,
  useGetNewsQuery,
  useGetArticleQuery,
  useSubmitFormMutation,
} = apiSlice;
