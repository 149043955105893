import React from "react";
import { useDocumentTitle } from "utils/hooks";

import MessageList from "components/MessageList";
import ContactUsButton from "components/ContactUsButton";

const LookingFor = () => {
    const title = "Cerchiamo";
    useDocumentTitle(title);
    return (
        <div className="wrapper">
            <MessageList what="lookingfor" />
            <ContactUsButton origin={title} />
        </div>
    );
};

export default LookingFor;
