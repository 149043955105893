import React from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Spinner from "components/Spinner";
import Navbar from "components/Navbar";

import Article from "./pages/Article";
import News from "./pages/News";
import LookingFor from "./pages/LookingFor";
import Mission from "./pages/Mission";
import Contacts from "./pages/Contacts";

import "./styles.css";

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="/lookingfor" replace />} />
          <Route path="/lookingfor" element={<LookingFor />} />
          <Route path="/news" element={<News />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/article/:filename" element={<Article />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
