import React, { useState, useEffect, useRef } from "react";
import { useSubmitFormMutation } from "services/apiSlice";

import InputField from "./InputField";
import DOMPurify from "dompurify";

import "./styles.css";

const ContactForm = ({ origin }) => {
  // initialize services -----------------------------------------------
  const [submitForm, { isLoading: isLoadingForm }] = useSubmitFormMutation();

  console.log("origin", origin);

  // state definition --------------------------------------------------
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    url: "",
    honeypot: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: DOMPurify.sanitize(value),
    });
  };

  const handleFileChange = (e) => {
    // retrieve the file from the event
    let file = e.target.files[0];

    // file extention validation
    const allowedExtensions = [".pdf"];
    const extension = file.name.split(".").pop();
    if (!allowedExtensions.includes("." + extension.toLowerCase())) {
      alert("Tipo di file non valido. Accettiamo solo pdf.");
      return;
    }

    // file size validation
    const maxSize = 20 * 1024 * 1024;
    if (file && file.size > maxSize) {
      alert("Allega un file sotto i 20 MB");
      handleFileReset();
    } else {
      // if the extension is valid, set the file into the state
      setFile(file);
    }
  };

  const handleFileReset = () => {
    setFile(null);
    // Reset the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("message", formData.message);
    formDataToSend.append("url", formData.url);
    formDataToSend.append("honeypot", formData.honeypot);
    formDataToSend.append("origin", origin);

    if (file) {
      formDataToSend.append("file", file);
    }

    // 'response' is unused
    const response = await submitForm(formDataToSend).unwrap();
    setFormData({
      name: "",
      email: "",
      message: "",
      url: "",
      honeypot: "",
    });
    handleFileReset();
  };

  // derived state to enable the form button
  useEffect(() => {
    const { name, email, message } = formData;
    setIsFormValid(name !== "" && email !== "" && message !== "");
  }, [formData]);

  return (
    <>
      <form
        className="container"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <h2 className="msg_title">Contattaci</h2>
        <InputField
          type="text"
          name="name"
          placeholder="Nome"
          value={formData.name}
          onChange={handleChange}
          required={true}
        />
        <InputField
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required={true}
        />
        <textarea
          type="text"
          name="message"
          placeholder="Messaggio*"
          value={formData.message}
          onChange={handleChange}
          required={true}
        />
        <InputField
          type="text"
          name="url"
          placeholder="URL sito personale"
          value={formData.url}
          onChange={handleChange}
          required={false}
        />
        <div className="inline-container">
          <input
            className="input-file"
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <button
            type="button"
            className="button-reset"
            onClick={handleFileReset}
          >
            Rimuovi
          </button>
        </div>

        {/* Honeypot field */}
        <input
          type="text"
          name="honeypot"
          value={formData.honeypot}
          style={{ display: "none" }}
          onChange={() => {}}
        />
        <button
          type="submit"
          disabled={!isFormValid || isLoadingForm}
          className={`button-submit ${!isFormValid || isLoadingForm ? "button-invalid" : "button-valid"}`}
        >
          {isLoadingForm ? <div className="spinner-sm" /> : "Invia"}
        </button>
      </form>
    </>
  );
};

export default ContactForm;
